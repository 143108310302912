import { countries } from './phone'
var countriesList = [...[], ...countries];
var seletList = [];
countriesList.forEach(value => {
    if(value.priority > 0 && value.areaCodes == null){
        return;
    }
    if (value.areaCodes && value.areaCodes.length > 0) {
        value.areaCodes.forEach(value2 => {
            if (seletList.indexOf(value.dialCode + '' + value2) === -1) {
                let item = {
                    label: value.name,
                    value: value.dialCode + '' + value2,
                    iso2: value.iso2
                }
                seletList.push(item)
            }
        })
    } else {
        if (seletList.indexOf(value.dialCode) === -1) {
            let item = {
                label: value.name,
                value: value.dialCode,
                iso2: value.iso2
            }
            seletList.push(item)
        }
    }
})

export default seletList