<template>
  <div class="information" v-loading="loading">
    <div class="userHead" v-if="!form.avatarUrl">
      <div class="userHead-icon">
        <svg-icon icon-class="bigUserHead" class="svg-icon"></svg-icon>
      </div>
      <div class="camera-icon" @click="upLoadImg()">
        <svg-icon icon-class="camera" class="svg-icon"></svg-icon>
      </div>
    </div>
    <div class="userHead" v-if="form.avatarUrl" @click="upLoadImg()">
      <el-image class="image" :src="form.avatarUrl"></el-image>
    </div>
    <div class="user-main">
      <div class="userName">{{ form.firstName }} {{ form.secondName }}</div>
      <div class="userInfo">
        <div class="user">{{ form.userType }}</div>
        <div
          class="Inactivated"
          v-show="form.emailFlag == 0 || form.dataFlag == 0"
        >
          {{ $t("lang.SText1") }}
        </div>
      </div>
      <div class="form">
        <div class="line line1">
          <div class="form-input firstName">
            <div class="title">{{ $t("lang.SText5") }}</div>
            <el-input
              maxlength="10"
              style="width: 240px"
              v-model="form.firstName"
            ></el-input>
          </div>
          <div class="form-input lastName">
            <div class="title">{{ $t("lang.SText6") }}</div>
            <el-input
              maxlength="10"
              style="width: 240px"
              v-model="form.secondName"
            ></el-input>
          </div>
        </div>
        <div class="line mail">
          <div class="title">
            <div class="title-text">
              {{ $t("lang.SText7") }}
              <div class="status" v-show="form.emailFlag == 0">
                {{ $t("lang.SText8") }}
              </div>
            </div>
            <div class="button" @click="Verify" v-show="form.emailFlag == 0">
              {{ $t("lang.SText9") }}
            </div>
          </div>
          <div class="form-input">
            <el-input disabled v-model="form.email"></el-input>
          </div>
        </div>
        <div class="line Country">
          <div class="title">{{ $t("lang.QText52") }}</div>
          <div class="form-input">
            <el-select
              v-model="form.country"
              placeholder=""
              filterable
              style="width: 100%"
              @change="clickCountry"
              remote
              :remote-method="findcountryOrRegionList"
              :loading="selectLoading"
            >
              <el-option
                v-for="(item, index) in countryList"
                :key="index"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="line Mobile">
          <div class="title">{{ $t("lang.SText10") }}</div>
          <div class="form-input unspin" style="position: relative">
            <el-select
              class="step1-select"
              v-model="dialCode1"
              placeholder=""
              filterable
              remote
              :remote-method="findPhoneList"
              :loading="selectLoading"
            >
              <el-option
                v-for="(item, index) in dialCodeList"
                :key="index"
                :label="item.iso2"
                :value="item.iso2"
              >
                <div class="phoneOption">
                  {{ item.iso2 + " | " + item.cname }}
                </div>
              </el-option>
            </el-select>
            <el-input
              style="width: 200px"
              v-model="mobile"
              @change="mobileCheck"
              class="phone-input"
              type="number"
              @keydown.native="limite"
            >
            </el-input>
            <span
              style="position: absolute; top: 48px; font-size: 12px; color: red"
              v-if="errorTip == true"
              >{{ $t("lang.SText11") }}</span
            >
          </div>
        </div>
        <div class="line Phone">
          <div class="title">{{ $t("lang.SText12") }}</div>
          <div class="form-input unspin">
            <el-select
              class="step1-select"
              v-model="dialCode2"
              placeholder=""
              filterable
              remote
              :remote-method="findPhoneList"
              :loading="selectLoading"
            >
              <el-option
                v-for="(item, index) in dialCodeList"
                :key="index"
                :label="item.iso2"
                :value="item.iso2"
              >
                <div class="phoneOption">
                  {{ item.iso2 + " | " + item.cname }}
                </div>
              </el-option>
            </el-select>
            <el-input
              style="width: 200px"
              class="phone-input"
              v-model="phone"
              type="number"
              @keydown.native="limite"
            >
            </el-input>
          </div>
        </div>
        <el-button
          class="bottom-button"
          @click="Save"
          :loading="updateMemberInfoLoading"
          >{{ $t("lang.SText13") }}</el-button
        >
      </div>
    </div>
    <el-dialog :visible.sync="upLoadFlag" width="900px" :destroy-on-close="true" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="kDialog" v-loading="loading">
        <div class="title">
          <div class="left">
            <!-- <svg-icon icon-class="upload" class="svg-icon"></svg-icon> -->
            {{ $t("lang.SText14") }}
          </div>
          <div class="close" @click="close()">
            <svg-icon class="close-icon" icon-class="close"></svg-icon>
          </div>
        </div>
        <div class="kDialog-main">
          <cropper-image
            v-if="upLoadFlag"
            class="upload"
            @uploadImg="uploadImg"
            :clear="clearImage"
          ></cropper-image>
        </div>
        <!-- <div class="background">
          <svg-icon class="back-icon" icon-class="uploadBack"></svg-icon>
        </div> -->
      </div>
      <!-- <k-dialog
        @close="upLoadFlag = false"
        @uploadSuccess="uploadSuccess"
      ></k-dialog> -->
    </el-dialog>
  </div>
</template>

<script>
import dialCode from "../../../utils/phone/index";
import CropperImage from "../../../components/cropperImage/cropperImage.vue";
export default {
  components: { CropperImage },
  data() {
    return {
      form: {},
      upLoadFlag: false,
      mobile: "",
      phone: "",
      dialCode1: "",
      dialCode2: "",
      loading: false,
      countryList: [],
      dialCodeList: [],
      errorTip: false,
      selectLoading: false,
      data: {},
      uploadAvatar: "",
      updateMemberInfoLoading: false,
      clearImage: false,
    };
  },
  created() {
    this.init();
    this.getPhoneList({ country: "" });
  },
  computed: {
    // dialCodeList: function () {
    //   return dialCode;
    // },
  },
  methods: {
    uploadImg(file) {
      let formData = new FormData();
      formData.append("loginCode", this.data.loginCode);
      formData.append("file", file);
      this.$session("token").get((value) => {
        formData.append("__sid", value);
      });
      // let formData = {
      //   file: file,
      //   loginCode: this.data.loginCode
      // }
      this.loading = true;
      this.$http
        .post(this.source.account_api.uploadAvatar, formData, {
          // responseType: 'blob'
        })
        .then((res) => {
          this.loading = false;
          if (res.data.result == "false") {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 5000,
            });
          } else {
            this.$notify.success({
              title: res.message,
              offset: 100,
              duration: 5000,
            });
            this.upLoadFlag = false;
            this.loading = false;
            this.getUserInfo();
            // this.$emit("uploadSuccess");
          }
        });
      return;
    },
    limite(e) {
      let key = e.key;
      if (key === "e" || key === ".") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    mobileCheck(value) {
      if (this.form.country == "CN") {
        // 验证手机号的正则表达式
        const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
        if (regMobile.test(value)) {
          // 合法的手机号
          this.errorTip = false;
          return;
        }
        // 不合法
        this.errorTip = true;
      } else if (this.form.country == "US") {
        const regMobile = /^[2-9]\d{2}[2-9](?!11)\d{6}$/;
        if (regMobile.test(value)) {
          // 合法的手机号
          this.errorTip = false;
          return;
        }
        // 不合法
        this.errorTip = true;
      } else {
        const regMobile = /^[1-9]\d*$/;
        if (regMobile.test(value)) {
          // 合法的手机号
          this.errorTip = false;
          return;
        }
        // 不合法
        this.errorTip = true;
      }
    },
    upLoadImg() {
      this.upLoadFlag = true;
    },
    init() {
      this.$session("userInfo").get((value) => {
        this.form = { ...{}, ...value };
        if (this.form.mobile) {
          let arr1 = this.form.mobile.split(" ");
          this.mobile = arr1[1] || arr1[0];
          if (arr1[1]) {
            this.dialCode1 = arr1[0];
          }
        }
        if (this.form.phone) {
          let arr1 = this.form.phone.split(" ");
          this.phone = arr1[1] || arr1[0];
          if (arr1[1]) {
            this.dialCode2 = arr1[0];
          }
        }
        this.uploadAvatar =
          global.baseUrl + this.source.account_api.uploadAvatar;
        this.data.loginCode = value.loginCode;
      });

      this.$session("countryList").get((value) => {
        this.countryList = value;
      });
    },
    Save() {
      if (this.errorTip) return;
      this.loading = true;
      this.form.lastName = this.form.secondName;
      if (this.mobile) {
        this.form.mobile = this.dialCode1 + " " + this.mobile;
      }
      if (this.phone) {
        this.form.phone = this.dialCode2 + " " + this.phone;
      }
      this.updateMemberInfoLoading = true;
      this.$ajax
        .cPost(this.source.account_api.updateMemberInfo, this.form)
        .then((res) => {
          if (res.data.result == "true") {
            this.$notify.success({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
            this.getUserInfo();
          } else {
            this.$notify.error({
              title: this.$t("lang.SText144"),
              offset: 100,
              duration: 3000,
            });
          }
          this.loading = false;
          this.updateMemberInfoLoading = false;
        });
    },
    getUserInfo() {
      this.$ajax.cPost(this.source.account_api.getUserInfo).then((res) => {
        if (res.data.result == "false") {
          this.$router.replace('/login')
        } else {
          this.form = res.data.user;
          this.$session("userInfo").set(res.data.user);
        }
      });
    },
    uploadSuccess() {
      this.upLoadFlag = false;
      this.getUserInfo();
    },
    Verify() {
      this.loading = true;
      this.$ajax
        .cPost(this.source.account_api.checkMembereEmail, {
          loginCode: this.form.loginCode,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.result == "false") {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 5000,
            });
          } else {
            this.$notify.success({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
            this.getUserInfo();
          }
        });
    },
    findcountryOrRegionList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.address_api.getCountryList, { name: e })
        .then((res) => {
          this.selectLoading = false;
          if ((res.data.result = "true")) {
            this.countryList = res.data.data;
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    clickCountry(e) {
      // this.dialCodeList.forEach((item) => {
      //   if (item.iso2 == e) {
      //     this.dialCode1 = item.value;
      //     this.dialCode2 = item.value;
      //   }
      // });
      console.log(e);
      this.$set(this, "dialCode1", "");
      this.$set(this, "dialCode2", "");
      if (e) {
        this.getPhoneList({ country: e }, "1");
      }
    },
    getPhoneList(data, val) {
      let that = this;
      that.$ajax.cPost(that.source.address_api.phone, data).then((res) => {
        if (res.data.result) {
          if (val == "1") {
            that.$set(this, "dialCode1", res.data.data[0].iso2);
            that.$set(this, "dialCode2", res.data.data[0].iso2);
          } else {
            that.dialCodeList = res.data.data;
          }
        } else {
          that.$notify.error({
            title: res.message,
            offset: 100,
            duration: 3000,
          });
        }
      });
    },
    findPhoneList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.address_api.phone, {
          dialCode: e,
          // country:this.form.countryOrRegion
        })
        .then((res) => {
          this.selectLoading = false;
          if ((res.data.result = "true")) {
            this.dialCodeList = res.data.data;
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    close() {
      this.upLoadFlag = false;
      this.clearImage = true;
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.information {
  width: 900px;
  display: flex;
  margin-left: 150px;
  .el-input__inner {
    height: 48px;
    font-size: 14px;
  }
  .step1-select {
    width: 100px;
  }
  .userHead {
    width: 200px;
    height: 200px;
    background-color: #f9f9f9;
    position: relative;
    .image {
      width: 200px;
      height: 200px;
    }
    .userHead-icon {
      color: #b3b3b3;

      .svg-icon {
        width: 130px;
        height: 146px;
        margin: 27px 0;
        fill: #8d8686;
        stroke: #8d8686;
      }
    }
    .camera-icon {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba($color: #e6e6e6, $alpha: 0.5);
      height: 50px;
      .svg-icon {
        width: 24px;
        height: 22px;
        margin: 14px 0;
        color: #fff;
      }
    }
  }
  .mail {
    .el-form-item__label {
      margin-bottom: -40px;
    }
    .Account-status {
      float: right;
      color: $background3;
      margin-right: 90px;
      font-size: 14px;
    }

    .Verify-button {
      float: right;
      font-size: 14px;
      color: $background2;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .user-main {
    margin-left: 65px;

    .userInfo {
      display: flex;
      .user {
        height: 32px;
        width: 108px;
        line-height: 32px;
        text-align: center;
        background-color: #e6e6e6;
        color: #808080;
        font-size: 20px;
        margin-top: 15px;
      }
      .Inactivated {
        height: 32px;
        width: 128px;
        line-height: 32px;
        text-align: center;
        color: $background3;
        font-size: 14px;
        margin-top: 15px;
        text-decoration: underline;
      }
    }

    .userName {
      font-size: 60px;
      color: $mainColor;
      text-align: left;
    }

    .form {
      padding: 10px 10px;
      margin-top: 28px;
      width: 500px;
      border-top: 2px solid #e6e6e6;
      .line {
        margin-bottom: 10px;
        .title {
          font-size: 20px;
          color: $fontColor3;
          text-align: left;
          line-height: 30px;
          padding-left: 10px;
        }
      }

      .line1 {
        display: flex;
        justify-content: space-between;
        .form-input {
          width: 240px;
        }
      }

      .mail {
        .title {
          display: flex;
          justify-content: space-between;

          .title-text {
            display: flex;
          }

          .status {
            color: $background3;
            margin-left: 20px;
            font-size: 14px;
          }

          .button {
            font-size: 14px;
            color: $background2;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .Mobile,
      .Phone {
        width: 200px;
        .form-input {
          display: flex;
          width: 300px;
        }
        .step1-select {
          font-size: 20px;
        }
      }

      .bottom-button {
        background-color: $mainColor;
        color: #fff;
        font-size: 30px;
        width: 100%;
        height: 64px;
        line-height: 63px;
        margin-top: 40px;
        cursor: pointer;
        padding: 0;
      }
    }
  }

  .phoneOption {
    font-size: 14px !important;
  }

  .el-dialog {
    margin-top: 100px !important;
    height: 520;
    margin: 0 auto;
    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;
      height: 100%;
    }
  }
  .kDialog {
    position: relative;
    .title {
      background-color: $mainColor;
      height: 40px;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      color: #fff;

      .left {
        line-height: 40px;
        padding-left: 50px;
        .svg-icon {
          margin-right: 20px;
          color: #fff;
          height: 36px;
          width: 36px;
        }
      }

      .close {
        width: 40px;
        height: 40px;
        margin: 0 25px;
        cursor: pointer;
        .svg-icon {
          height: 15px;
          margin: 13px 0;
        }
        .close-icon {
          width: 15px;
          height: 15x;
          fill: #fff;
          stroke: #fff;
          cursor: pointer;
        }
      }
    }
    .kDialog-main {
      padding: 20px;
      .el-upload-dragger {
        width: 996px;
        border: 4px dashed #b3dcf6;
        height: 492px;
        margin-top: 150px;
        position: relative;
        background: #fff;
        z-index: 100;
      }

      .el-upload__text {
        font-size: 30px;
        color: $mainColor;
        top: 120px;
        line-height: 65px;
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;

        .button {
          background-color: $mainColor;
          color: #fff;
          width: 400px;
          height: 64px;
          line-height: 64px;
          margin: 30px auto;
        }
      }

      .upload-back {
        position: absolute;
        background-color: rgba($color: $background, $alpha: 0.25);
        height: 246px;
        width: 496px;
        bottom: 0;
      }

      .el-upload__tip {
        text-align: left;
        margin-left: 140px;
        font-size: 20px;
        color: $mainColor;
        margin-top: 20px;
      }
    }

    .background {
      height: 630px;
      width: 703px;
      position: absolute;
      bottom: -50px;
      right: -125px;
      .back-icon {
        height: 630px;
        width: 703px;
      }
    }
  }
}
</style>
